<template>
  <div
    class="form-control cursor-pointer mh-40"
    @click="togglePicker"
    :class="{ 'icon-not-selected': String.isNullOrWhiteSpace(modelValue) }"
    v-if="this.formInputCreateType"
  >
    <span v-bind="$attrs" class="d-flex align-items-center">
      <i class="icon-picker-current-icon pe-2" :class="modelValue"></i>
      {{ modelValue }}
    </span>
  </div>
  <span v-bind="$attrs" @click="togglePicker" v-else>
    <i class="icon-picker-current-icon" :class="modelValue"></i>
  </span>
  <div class="icon-picker-modal icon-picker-open" v-if="isVisible">
    <div class="icon-picker-modal--content">
      <div class="icon-picker-modal--header">
        <div class="icon-picker-modal--header-logo-area">
          <span class="icon-picker-modal--header-logo-title">
            {{
              $t(
                "Components.IconPicker.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </span>
        </div>
        <div class="icon-picker-modal--header-close-btn" @click="closePicker">
          <i class="bi bi-x" title="Close"></i>
        </div>
      </div>
      <div class="icon-picker-modal--body">
        <div class="icon-picker-modal--sidebar">
          <div class="icon-picker-modal--sidebar-tabs">
            <div
              class="icon-picker-modal--sidebar-tab-item"
              data-library-id="all"
              v-for="tab in tabs"
              :key="tab.id"
              :class="{ 'aesthetic-active': isActiveTab(tab.id) }"
              @click="setActiveTab(tab)"
            >
              <i :class="tab.icon"></i>
              <span>{{ tab.title }}</span>
            </div>
          </div>
          <div class="icon-picker-sidebar-preview">
            <div class="icon-picker-icon-item">
              <div class="icon-picker-icon-item-inner">
                <i :class="activeGlyph"></i>
                <div class="icon-picker-icon-item-name">
                  {{ activeGlyph }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="icon-picker-modal--icon-preview-wrap">
          <div class="icon-picker-modal--icon-search">
            <input
              class="form-control"
              v-model="filterText"
              :placeholder="
                $t(
                  'Components.IconPicker.Filter',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
            />
            <i class="fas fa-search"> </i>
          </div>
          <div class="icon-picker-modal--icon-preview-inner">
            <div class="icon-picker-modal--icon-preview">
              <div
                class="icon-picker-icon-item"
                v-for="glyph in glyphs"
                :key="glyph"
                :class="{ 'aesthetic-selected': isActiveGlyph(glyph) }"
                @click="setActiveGlyph(glyph)"
              >
                <div class="icon-picker-icon-item-inner">
                  <i :class="glyph"></i>
                  <div class="icon-picker-icon-item-name">
                    {{ getGlyphName(glyph) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="icon-picker-modal--footer">
        <button class="btn btn-success me-1" @click="insert">
          {{
            $t(
              "Components.IconPicker.Add",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button class="btn btn-danger" @click="clear" v-if="modelValue">
          {{
            $t(
              "Components.IconPicker.Delete",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/iconpicker.css";
import "@/assets/css/fa-all.css";

import { ref, computed } from "vue";
import fontLibrary from "@/assets/js/fonts.js";

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "Icon Picker",
    },
    modelValue: {
      type: String,
      default: "",
    },
    allIconsText: {
      type: String,
      default: "",
    },
    formInputCreateType: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const filterText = ref("");
    const activeGlyph = ref(props.modelValue);
    const isVisible = ref(false);

    const tabs = [
      {
        id: "all",
        title: props.allIconsText,
        icon: "fas fa-star-of-life",
        link: "all",
      },
      {
        id: "far",
        title: "Font Awesome Regular",
        icon: "fab fa-font-awesome-alt",
        link: fontLibrary.fontAwesome.variants.regular,
      },
      {
        id: "fas",
        title: "Font Awesome Solid",
        icon: "fab fa-font-awesome",
        link: fontLibrary.fontAwesome.variants.solid,
      },
      {
        id: "fab",
        title: "Font Awesome Brands",
        icon: "fab fa-font-awesome-flag",
        link: fontLibrary.fontAwesome.variants.brands,
      },
    ];

    const activeTab = ref(tabs[0]);

    const allGlyphs = [].concat(
      tabs[1].link.icons,
      tabs[2].link.icons,
      tabs[3].link.icons
    );

    const glyphs = computed(() => {
      let _glyphs = [];
      if (activeTab.value.id !== "all") {
        _glyphs = activeTab.value.link.icons;
      } else {
        _glyphs = allGlyphs;
      }

      if (filterText.value != "") {
        const _filterText = filterText.value.toLowerCase();
        _glyphs = _glyphs.filter(
          (item) => item.substr(7, filterText.value.length) === _filterText
        );
      }
      return _glyphs;
    });

    const setActiveGlyph = (glyph) => {
      activeGlyph.value = glyph;
    };

    const isActiveGlyph = (glyph) => {
      return activeGlyph.value == glyph;
    };

    const isActiveTab = (tab) => {
      return tab == activeTab.value.id;
    };

    const setActiveTab = (tab) => {
      activeTab.value = tab;
      // filterText.value=''; //nice feature
    };

    const getGlyphName = (glyph) =>
      glyph.replace(/f.. fa-/g, "").replace("-", " ");

    const insert = () => {
      context.emit("update:modelValue", activeGlyph.value);
      isVisible.value = false;
    };

    const clear = () => {
      context.emit("update:modelValue", "");
      activeGlyph.value = "";
      isVisible.value = false;
    };

    const togglePicker = () => {
      isVisible.value = !isVisible.value;
    };

    const closePicker = () => {
      isVisible.value = false;
    };

    return {
      fontLibrary,
      tabs,
      isActiveTab,
      setActiveTab,
      glyphs,
      activeGlyph,
      getGlyphName,
      filterText,
      setActiveGlyph,
      isActiveGlyph,
      insert,
      clear,
      isVisible,
      togglePicker,
      closePicker,
    };
  },
};
</script>
